import * as React from "react";
import { Parallax } from "react-parallax";
import { Card, Col, Container, Row } from "react-bootstrap";

import image from "../../images/meeting.jpg";
import stretchinSvg from "../../images/stretching.svg";
import chooseSvg from "../../images/choose.svg";
import Layout from "../../components/layout";
import Section from "../../components/section";
import Typography from "../../components/typography";
import ScheduleForm from "../../components/scheduleForm";
import { StaticImage } from "gatsby-plugin-image";

const WorkingCapitolLoan = () => {
  return (
    <Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
      <Section size="md" color="light" staticHeight>
        <Parallax
          className="d-flex justify-content-center align-items-center h-100"
          bgImage={image}
          strength={750}
        >
          <Container className="text-center h-100">
            <div>
              <Typography type="head">Working Capital Loan</Typography>
            </div>
          </Container>
        </Parallax>
      </Section>
      <Section size="sm" color="dark" bg="light">
        <Container className=" py-5">
          <div className="hide-on-mobile">
          <Typography className="mb-3" type="title">
            What is a Working Capital Loan?
          </Typography>
          </div>
          <div className="hide-on-desktop">
          <Typography className="mb-3 text-center" type="title">
            What is a Working Capital Loan?
          </Typography>
          </div>
          <Typography type="paragraph">
            A working capital loan is best used to fund short-term, daily
            operational expenses such as payroll and purchasing materials.
            Working capital is the difference between a company's current assets
            and current liabilities. Current assets are assets such as accounts
            receivable, inventory, and other short-term holdings that can be
            turned into cash within one year. Current liabilities are
            liabilities such as short-term debt and other typical operational
            expenses that will be payed within one year. Working capital loans
            are short-term loans meant to satisfy immediate cash flow and
            business needs. Thus, these loans are not optimal for long-term
            costs, investments, or growth due to their short repayment terms.
          </Typography>
        </Container>
      </Section>
      <Section color="light" bg="dark">
        <Container className="text-center py-5">
          <Typography type="title">Loan Details</Typography>
          <Row className="d-flex justify-content-center mx-auto">
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Max. Loan Amount</Typography>
              <Typography type="subject">Up to $400,000</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Loan Term</Typography>
              <Typography type="subject">6 - 8 months</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Interest Rates</Typography>
              <Typography type="subject">Min. of 1.15%</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Speed of Funding</Typography>
              <Typography type="subject">24 hours</Typography>
            </Card>
          </Row>
          <Typography className="mt-5" type="title">
            Loan Requirements
          </Typography>
          <Row className="d-flex justify-content-center mx-auto">
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">
                Avg. Monthly Bank Deposits
              </Typography>
              <Typography type="subject">$15,000+</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Credit Score</Typography>
              <Typography type="subject">500+</Typography>
            </Card>
            <Card
              style={{ maxWidth: "200px", minHeight: "125px" }}
              className="d-flex justify-content-center align-items-center mx-3"
            >
              <Typography type="paragraph">Time in Business</Typography>
              <Typography type="subject">6+ months</Typography>
            </Card>
          </Row>
        </Container>
      </Section>
      <Section size="md">
        <Container
          fluid
          className="d-flex flex-nowrap h-100 align-items-center h-100 p-5"
        >
          <div className="nom d-inline-block me-5">
            <img
              className="h-100"
              style={{ maxHeight: "275px" }}
              src={stretchinSvg}
              alt="Two People Agreeing on Document Terms"
            ></img>
          </div>
          <div className="d-inline-block flex-full">
            <Row className="w-100 h-100">
              <div className="flex-full">
                <Typography type="title">Pros</Typography>
                <ul>
                  <li>Fast capital to swiftly stabilize cash flow</li>
                  <li>
                    Faster funding and easier to qualify for than bank loans
                  </li>
                  <li>
                    Retain ownership over your business unlike with equity
                    investments
                  </li>
                  <li>No collateral needed</li>
                  <li>Financial flexibility --- no restrictions on use</li>
                </ul>
              </div>
              <div className="flex-full mr-5">
                <Typography type="title">Cons</Typography>
                <ul>
                  <li>Shorter duration is not ideal for long-term costs</li>
                  <li>Factor rates are higher than long-term loans</li>
                  <li>
                    Harder to qualify with poor business or personal credit
                    history
                  </li>
                </ul>
              </div>
            </Row>
          </div>
        </Container>
      </Section>
      <Section size="lg" bg="dark" color="light">
        <Container className="py-5">
          <div className="hide-on-mobile">
          <Typography type="title">
            How To Apply for a Working Capital Loan
          </Typography>
          </div>
          <div className="hide-on-desktop text-center">
          <Typography type="title">
            How To Apply for a Working Capital Loan
          </Typography>
          </div>
          <Typography type="paragraph">
            With our simple online application, the process to receive a working
            capital loan is quick and easy.
          </Typography>
          <Row style={{ marginTop: "10%" }}>
            <div className="d-flex align-items-center flex-full tl-min py-4">
              <Typography type="subject">
                <span className="numList me-3">1</span>
              </Typography>
              <div>
                <Typography type="subject">Prequalify Online</Typography>
                <Typography type="paragraph">
                  Follow steps and enter basic information.
                </Typography>
              </div>
            </div>
            <div className="d-flex align-items-center flex-full tl-min py-4">
              <Typography type="subject">
                <span className="numList me-3">2</span>
              </Typography>
              <div>
                <Typography type="subject">Online Approval</Typography>
                <Typography type="paragraph">
                  After online approval, a consultant will reach out to discuss
                  loan options.
                </Typography>
              </div>
            </div>
            <div className="d-flex align-items-center flex-full tl-min py-4">
              <Typography type="subject">
                <span className="numList me-3">3</span>
              </Typography>
              <div>
                <Typography type="subject">Recieve Secure Funding</Typography>
                <Typography type="paragraph">
                  Funds will be directly deposited into your bank account and
                  available for immediate use.
                </Typography>
              </div>
            </div>
          </Row>

          <div style={{ marginTop: "10%" }}>
            <div className="hide-on-mobile">
            <Typography type="title">Needed Documentation</Typography>
            </div>
            <div className="hide-on-desktop text-center">
            <Typography type="title">Needed Documentation</Typography>
            </div>
            <ul className="d-flex flex-wrap">
              <li className="m-0 me-5 mb-3">Signed business loan agreement</li>
              <li className="m-0 me-5 mb-3">
                If owned, business mortgage statement; if rented, business lease
                agreement
              </li>
              <li className="m-0 me-5 mb-3">
                Driver's license/government-issued photo ID
              </li>
              <li className="m-0 me-5 mb-3">Recent bank statements</li>
            </ul>
          </div>
        </Container>
      </Section>
      <Section size="md">
        <Container className="d-flex flex-nowrap h-100 align-items-center h-100 py-5">
          <div className="nom d-inline-block flex-full me-5">
            <img
              className="h-100"
              style={{ maxHeight: "400px" }}
              src={chooseSvg}
              alt="Two People Agreeing on Document Terms"
            ></img>
          </div>
          <div className="d-inline-block nom">
            <Typography type="title">
              Best Use of a Working Capital Loan
            </Typography>
            <div className="d-flex flex-wrap mx-auto">
              <ul className="flex-full me-3">
                <li>Stabilizing cash flow</li>
                <li>Payroll</li>
                <li>Hiring</li>
              </ul>
              <ul className="flex-full">
                <li>Marketing</li>
                <li>Adding inventory</li>
                <li>Equipment costs</li>
              </ul>
            </div>
          </div>
          <div className="d-inline-block nomm">
            <Typography className="text-center" type="title">
              Best Use of a Working Capital Loan
            </Typography>
            <div className="d-flex flex-wrap mx-auto">
              <ul className="flex-full me-3">
                <li>Stabilizing cash flow</li>
                <li>Payroll</li>
                <li>Hiring</li>
                <li>Marketing</li>
                <li>Adding inventory</li>
                <li>Equipment costs</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default WorkingCapitolLoan;
